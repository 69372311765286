.quickaccesslabel {
  font-size: 1.2vw;
  font-family: var(--font-brandon-medium);
  color: var(--shimpment_text_color);
  margin-bottom: 0.4vw;
}

.submit_btn_size {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 2rem;
  border-radius: 4px;
  height: 45px;
  font-size: var(--font-size-22);
}

.submit_btn_size:hover {
  background-color: var(--color-white);
  color: var(--raven-red);
  transition: all 0.5s ease;
}

.quickaccessicon svg {
  height: 2.5vw;
  width: 2.5vw;
}

.quickaccessblock :hover svg path {
  fill: var(--raven-red);
  cursor: pointer;
}

.quickaccessblock :hover label {
  color: var(--raven-red);
  cursor: pointer;
}

.quickaccessblock {
  width: 8.13vw;
  border: 1px solid transparent;
  cursor: pointer;
}

.quickaccessblock:hover {
  border: 1px solid var(--raven-red);
  border-radius: 3px;
}

.quickaccessblock label {
  font-size: 1vw;
}

/* external laundary */
.external_laundary_batchinfocard {
  width: calc(100% / 5);
}

.DeliveredPopupContent {
  display: flex;
  flex-direction: column;
  height: 67vh;
}

/* Quality control */

.qclabel {
  font-size: min(20px, 1.2vw);
  font-family: var(--font-brandon-medium);
  color: var(--raven-gray);
}

.qcvalue {
  font-size: clamp(1.5rem,1vw,2.5rem);
  font-family: var(--font-brandon-regular);
  color: white;
}

.commenttext {
  display: -webkit-box;
  height: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.qcedit svg {
  width: 2vw;
  height: 2vw;
}

.pdqcIssues .qcedit svg {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.qceditblock {
  min-height: 45px !important;
  height: 45px !important;
}

.edit svg path {
  fill: var(--raven-gray);
}

.qcedit svg:hover {
  cursor: pointer;
}

.qcedit svg path {
  fill: var(--raven-gray);
}

.qcedit svg:hover path {
  fill: var(--raven-red);
}

/* merchandising organism */

.dimension_popup_body {
  max-height: 70vh;
  overflow: auto;
}

.merchandleftblock img {
  height: 100%;
  object-fit: contain;
  width: 100%;
  /* min-height: 263px; */
}

.merchandleftblock_add svg {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.dimensionadd_btn svg {
  height: 50px;
  cursor: pointer;
}

.dimensionadd_btn svg:hover path {
  fill: var(--raven-red);
}

.merchissuesblock {
  height: 148px;
  overflow-y: auto;
}

.add_btn svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.add_btn svg:hover path {
  fill: var(--raven-red);
}

.itemsblock {
  height: 64vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 64vh;
  flex: 1;
}

.medailibrarypop > div {
  width: 90vw !important;
  max-width: 90vw !important;
}

/* prepublish */
.merchrackbox {
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 7);
  box-shadow: 0px 1px 6px #00000029;
  margin-right: 17px;
}

/* Quality assurance  */

.QA_main_block {
  height: calc(100vh - 200px);
}

.QA_first_block {
  /* height: 65%; */
  flex: 1;
  overflow: auto;
}

.qaissuesoverview {
  flex: 1;
  overflow: auto;
}

/* Finishing  */
.finising_body {
  height: calc(100vh - 310px);
  overflow-y: scroll;
}

.finishing_card_outer {
  text-align: center;
  /* min-width: 300px; */
  /* width: 300px !important; */
  padding: unset !important;
}

.finishing_card_outer p {
  font-size: clamp(1.5rem, 1vw, 2.5rem) !important;
}

.finishingcard {
  padding: 38px 30px !important;
  border-radius: 50% !important;
  /* height: 300px;
  width: 300px;
  min-height: 300px;
  min-width: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
}

.qaimg {
  height: 92%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1366px) {
  .qaimg img {
    height: 15vw;
  }
}

.createbatch svg {
  width: 20px;
  margin-right: 10px;
  height: 20px;
}

.createbatch svg path {
  fill: white;
}

.createbatch:hover svg path {
  fill: var(--raven-red);
}

.barcode_laundry {
  height: 60vh;
  color: #54596070;
  font-size: 33px;
  font-weight: bold;
}

/* .product_width {
  width: 230px;
} */

.laundry_product_close_btn:hover svg path {
  fill: var(--raven-red);
}

.moveitem_to_any_stage {
  width: calc(100% / 5) !important;
  padding: 0.5vw !important;
}

.moveitem_to_any_stage_inner {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 6px #00000029;
}

.moveitem_to_any_stage_inner:hover {
  transform: scale(1.02);
  transition: all 0.5s;
  cursor: pointer;
  box-shadow: 0px 0px 7px 1px #0000009c !important;
}

@media (max-width: 1400px) {
  .external_laundary_batchinfocard {
    width: calc(100% / 4);
  }
  .QA_main_block {
    height: calc(100vh - 190px);
  }
  /* .QA_first_block {
    height: 65%;
  } */
  .finising_body {
    height: calc(100vh - 250px);
  }
}

@media (max-width: 1200px) {
  .external_laundary_batchinfocard {
    width: calc(100% / 3);
  }

  .medailibrarypop > div {
    width: 95vw !important;
    max-width: 95vw !important;
  }

  .finising_body {
    height: calc(100vh - 260px);
  }
}

.page_title {
  font-size: min(40px, 2.063vw);
}
