html,
body {
  font-family: var(--font-brandon-regular);
}

.loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 99999 !important;
}

.disable-screen {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.too-many-requests-popup {
  width: 120vh;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.too-many-requests-popup-close {
  background: none;
  border: none;
  box-shadow: none;
}

.too-many-requests-popup-close svg {
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}

.too-many-requests-popup-close svg path {
  fill: var(--raven-gray);
}

.too-many-requests-popup-close:hover svg path {
  fill: var(--raven-red);
}

/* sidebar  starts*/
.sidenav {
  transition: width 0.3s ease;
}

.sidebar_item:hover,
.sidebar_item:hover svg path {
  color: var(--raven-red);
  fill: var(--raven-red);
}

.sidebar-text {
  opacity: 0;
  transition: all 0.3s ease;
  width: max-content;
}

.sidenav.expanded {
  width: 250px;
  max-width: 100%;
  transition: all 0.3s ease;
  box-shadow: 6px -2px 20px 2px grey;
}

.sidenav.expanded .sidebar-text {
  opacity: 1;
  margin-left: 8px;
  /* Or your preferred spacing */
}

/* sidebar  ends*/

input,
select {
  min-height: 45px;
}

input[type="file"] {
  min-height: auto !important;
}

select option:hover {
  background: var(--raven-red);
}

input[type="checkbox"] {
  min-height: auto;
  border: 1px solid var(--raven-gray);
  border-color: var(--raven-gray) !important;
  width: 1.1rem;
  height: 1.1rem;
  background-color: var(--table-header-bg-color);
}

input[type="checkbox"].form-check-input:checked {
  background-color: var(--raven-red);
  border-color: var(--raven-red) !important;
}

input[type="radio"] {
  min-height: auto;
  border: 1px solid var(--raven-gray);
  border-color: var(--raven-gray);
}

.profile_name {
  width: 8rem;
  min-width: 8rem;
  max-width: 8rem;
}

.change_password_btn:hover {
  color: var(--raven-red) !important;
}

/* Page Title */
.page-title {
  font-family: var(--font-brandon-bold);
  font-size: min(40px, 2.063vw);
  position: sticky;
  top: 0px;
  background: var(--color-white);
  z-index: 2;
  color: var(--color-black);
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 10px !important;
}

/* Page Sub Title */
.page-sub-title {
  font-family: var(--font-brandon-bold);
  font-size: 1.063vw;
  position: sticky;
  top: 96px;
  background: var(--color-white);
  z-index: 1;
  color: var(--color-black);
}

/* Submit button */
.submit-btn,
.create-btn {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 1rem;
  border-radius: 4px;
  height: 50px;
  font-size: var(--font-size-22);
  min-width: max-content;
}

.submit-btn:hover,
.create-btn:hover {
  background-color: var(--color-white);
  color: var(--raven-red);
  transition: all 0.5s ease;
  border-color: var(--raven-red) !important;
}

.submit-btn:hover svg,
.hoversvg svg:hover path {
  fill: var(--raven-red);
}

.submit-btn_reverse {
  color: var(--raven-red);
  background-color: var(--color-white);
  border: 1px solid var(--raven-red);
  padding: 0rem 2rem;
  border-radius: 4px;
  height: 50px;
  font-size: var(--font-size-22);
  transition: all 0.5s ease;
}

.submit-btn_reverse:hover {
  background-color: var(--raven-red);
  color: var(--color-white);
}

/* Cancel Button */
.reject-btn {
  background-color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  font-size: var(--line-height-22);
  line-height: var(--line-height-31);
  color: var(--color-white);
  padding: 0rem 1rem;
  border-radius: 4px;
  height: 45px;
  justify-content: center;
}

.reject-btn:hover {
  background-color: var(--color-white);
  color: var(--raven-gray);
}

.reject-btn-reverse {
  width: 8rem;
  padding: 0.7rem;
  height: 50px;
  margin-right: 1.5rem;
  color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white !important;
}

.reject-btn-reverse:hover {
  background-color: var(--raven-gray) !important;
  color: var(--color-white);
}

.reject-btn svg:hover path {
  fill: var(--raven-gray) !important;
}

.reject-btn,
.back-btn:hover svg path {
  fill: var(--raven-gray);
}

/* Confirm-btn */
.accept-btn {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 1rem;
  border-radius: 4px;
  height: 45px;
  font-size: var(--line-height-22);
  justify-content: center;
  line-height: var(--line-height-31);
}

.accept-btn:hover {
  background-color: var(--color-white);
  color: var(--raven-red);
  transition: all 0.5s ease;
}

.accept-btn,
.submit-btn:hover svg path {
  fill: var(--raven-red);
}

/* Back-btn */
.back-btn {
  background-color: #545960;
  border: 1px solid var(--raven-gray);
  color: var(--color-white);
  padding: 0rem 2rem;
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-22);
}

.back-btn:hover {
  background-color: var(--color-white);
  color: var(--raven-gray);
}

/* Cancel Button */
.reject-btn {
  background-color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  font-size: var(--line-height-22);
  line-height: var(--line-height-31);
  color: var(--color-white);
  padding: 0rem 1rem;
  border-radius: 4px;
  height: 45px;
}

.reject-btn:hover {
  background-color: var(--color-white);
  color: var(--raven-gray);
}

/* Confirm-btn */
.accept-btn {
  background-color: var(--raven-red);
  border: 1px solid var(--raven-red);
  color: var(--color-white);
  padding: 0rem 1rem;
  border-radius: 4px;
  height: 45px;
  font-size: var(--line-height-22);
  line-height: var(--line-height-31);
}

.accept-btn:hover {
  background-color: var(--color-white);
  color: var(--raven-red);
  transition: all 0.5s ease;
  border: 1px solid var(--raven-red);
}

/* Table header */

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

table thead tr {
  background-color: var(--table-header-bg-color);
  border: 1px solid var(--table-header-bg-color);
}

table thead tr th {
  color: var(--table-heading-text-color);
  font-family: var(--font-brandon-medium);
  font-weight: normal;
  font-size: var(--font-size-22);
  line-height: var(--line-height-26);
  padding: 1rem 0.5rem !important;
}

table tr td {
  font-size: var(--font-size-20);
  word-wrap: break-word !important;
  word-break: break-word !important;
}

table thead tr th:nth-child(1),
table tbody tr td:nth-child(1) {
  padding-left: 2rem !important;
}

table thead::after {
  content: "-";
  color: var(--color-white);
  line-height: 1vh;
  display: block;
}

table tbody {
  border: 0px !important;
  color: var(--raven-gray);
}

table tbody tr {
  border: 1px solid var(--table-body-border-color);
  vertical-align: middle;
}

/* Pagination */

.pagination .active {
  color: var(--color-white);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
  background-color: var(--raven-red);
}

.pagination .active:hover {
  color: var(--color-white) !important;
}

.pagination button {
  color: var(--raven-gray);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
}

.pagination button:hover {
  color: var(--raven-red);
}

.pagination button:disabled {
  color: var(--raven-gray) !important;
}

.select__indicator-separator {
  display: none;
}

/* Modal */

/* Modal Header */
.modal-header {
  position: relative;
  padding: 1rem 0px;
}

.btn-close {
  background: none;
}

.modal .btn-close svg {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  bottom: 19px;
  right: 0px;
}

.modal .btn-close svg path {
  background-color: var(--raven-gray);
}

.modal .btn-close:hover svg path {
  fill: var(--raven-red);
}

.modal-content {
  padding: 0px 1rem;
}

.modal {
  z-index: 9999 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}

/* Modal Title */
.modal-title {
  font-family: var(--font-brandon-medium);
  color: var(--raven-gray);
  /* font-size: min(40px, 1.41vw); */
  line-height: var(--line-height-37);
}

/* Modal Body */
.modal-body {
  font-family: var(--font-brandon-medium);
  color: var(--raven-gray);
  font-size: var(--font-size-32);
  line-height: var(--line-height-46);
}

label {
  font-size: var(--font-size-20);
  color: var(--label-color);
}

.alignhelptext {
  position: absolute;
  top: 12px;
}

.custom_astrick:after {
  content: " *";
  color: var(--raven-red);
}

.custom_astrick_before {
  font-family: var(--font-brandon-regular);
  color: var(--label-color);
  font-size: var(--font-size-14);
}

.custom_astrick_before:before {
  content: " *";
}

.tabbtn {
  background-color: var(--tab-color);
  color: var(--tab-text-color);
  padding: 0rem 2px;
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-18);
  cursor: pointer;
}

.active_tabbtn {
  background-color: #545960;
  color: var(--color-white);
  padding: 0rem 2px;
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-18);
  cursor: pointer;
}

.move_nav_menu:nth-child(1) {
  padding-left: 0 !important;
}

.move_nav_menu:last-child {
  padding-right: 0 !important;
}

.react-select-prefix__input {
  height: 30px;
  min-height: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.shadow {
  box-shadow: 0 1px 6px #00000029 !important;
}

.sliderRed {
  accent-color: var(--raven-red);
}

.date-container {
  position: relative;
  cursor: pointer;
}

.report-date-container {
  position: relative;
  cursor: pointer;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.report-date-container .react-datepicker-wrapper {
  width: 100%;
}

.report-date-container input {
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 0px 10px;
}

.report-date-container input:disabled {
  background: hsl(0, 0%, 95%) !important;
}

.cal-open-button {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 35px;
  height: 40px;
  /* background: #fff; */
  pointer-events: none;
  justify-content: center;
  display: flex;
  align-items: center;
}

.equal_block {
  flex: 1 1 auto;
}

.table_actions {
  width: 12rem;
  max-width: 12rem;
  min-width: 12rem;
  text-align: center;
}

.choose-file {
  font-size: var(--font-size-18);
}

/* .css-blhudp-control:hover {
  border-color: var(--raven-red) !important;
  /* outline: 1px solid var(--raven-red) !important;
} */

/* .css-blhudp-control,
.select__control:focus {
  border-color: var(--raven-red) !important;
  /* outline: 1px solid var(--raven-red) !important;
  box-shadow: none; 
} */

.date_of_delivery .react-datepicker-wrapper {
  width: 100% !important;
}

.multi-border-none .react-select-prefix__control {
  border: 0;
}

.react-select-prefix__control {
  min-height: 45px !important;
}

/* input:focus,
.form-control:focus {
  border-color: var(--raven-red) !important;
  outline: 1px solid var(--raven-red);
} */

/* shipment brand partner */
.scards {
  /* height: 14vh;
  width: 14vw;
  margin: 0px 35px 35px 0px; */
  padding: 16px;
}

.scards img {
  height: 18vh;
  width: 18vw;
  object-fit: contain;
}

.scards:hover {
  transform: scale(1.02);
  transition: all 0.5s;
  box-shadow: 0px 0px 7px 1px #0000009c !important;
  cursor: pointer;
}

@media (max-width: 1400px) {
  /* Page Title */
  /* .page-title {
    top: 70px;
  } */

  .scards img {
    height: 17vh;
    width: 17vw;
    object-fit: contain;
  }
}

/* scrool bar */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--raven-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--raven-red);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--raven-red);
}

/* create shipment baarcode */
.create_shipment_barcode p {
  font-size: 1.2vw;
  font-family: var(--font-brandon-medium);
  color: var(--raven-gray);
}

.create_shipment_barcode svg path {
  fill: var(--icon-green);
}

.create_shipment_barcode img {
  height: 8vw;
}

a[href="https://markerjs.com/"]
{
  display: none !important;
}

.activeblocks {
  width: 8rem;
  padding: 0.7rem;
  height: 50px;
  cursor: pointer;
  margin-right: 1.5rem;
  background: var(--raven-gray);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shipment-barcode {
  width: 30vw;
}

.mail-temp-langauges button {
  text-decoration: underline;
}

.mail-temp-langauges button:hover {
  text-decoration: underline;
  color: var(--raven-red);
}

.blocks {
  width: 8rem !important;
  padding: 0.7rem;
  height: 50px;
  margin-right: 1.5rem;
  color: var(--raven-gray);
  border: 1px solid var(--raven-gray);
  cursor: pointer;
  line-height: initial;
}

.activeblocks {
  width: 8rem;
  padding: 0.7rem;
  height: 50px;
  cursor: pointer;
  margin-right: 1.5rem;
  background: var(--raven-gray);
  color: white;
  line-height: initial;
}

.modal-body {
  line-height: initial;
}

.merchandingissues .swiper {
  width: 100%;
  height: 205px;
}

.merchandingissues .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchandingissues .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.merchandingissues .swiper-button-prev,
.merchandingissues .swiper-button-next {
  left: unset !important;
  right: 0px !important;
  rotate: 90deg;
  width: unset;
}

.merchandingissues .swiper-button-prev {
  top: 22px !important;
}

.merchandingissues .swiper-button-next {
  top: 116px !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: unset !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "prev";
  background: red;
  font-size: min(20px, 1.2vw) !important;
  color: white;
  border-radius: 50%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
}

.c_white {
  color: white !important;
}

.tableactive {
  color: var(--raven-red);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-22);
  background-color: var(--raven-red);
  text-decoration: underline;
}

.tableinactive {
  color: var(--raven-gray);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-22);
}

.text-revert {
  text-align: unset !important;
}

.modal-commentpopup {
  max-width: 27vw !important;
  width: 27vw !important;
}

.merchandising-image-swiper .swiper {
  /* position: unset !important; */
  height: 100% !important;
}

.merchandising-image-swiper .swiper .swiper-button-next,
.swiper-button-prev {
  /* top: 135px !important; */
  z-index: 1 !important;
}

.merchandising-image-swiper .swiper .swiper-pagination {
  z-index: 1 !important;
}

.data-entry-image-swiper .swiper {
  position: unset !important;
  width: 100%;
}

.data-entry-image-swiper .swiper .swiper-button-next,
.swiper-button-prev {
  /* top: 135px !important; */
  z-index: 1 !important;
}

.data-entry-image-swiper .swiper .swiper-pagination {
  bottom: -25px !important;
  z-index: 1 !important;
}

/* internal laundary */

.internal_laundary_move_item_body .admin_admincard__JWnm9 {
  padding: 1vw !important;
}

.transfer_to_modal .modal-dialog {
  width: 80vw !important;
  max-width: 80vw !important;
}

.react-select-prefix__menu {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.react-select-prefix__multi-value__label {
  white-space: wrap !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.table > thead {
  vertical-align: baseline !important;
  position: sticky;
  top: -2px !important;
}

.upload_media_lib_popup .modal-dialog {
  max-width: 96vw !important;
  width: 96vw !important;
}

.moving_item_popup {
  font-size: unset !important;
  line-height: unset !important;
  text-align: left !important;
}

.flex-1 {
  flex: 1 !important;
}

.roles_bg_my_profile {
  background-color: #e9ecef;
}

tbody tr:hover {
  background-color: var(--table-header-bg-color);
  transition: all 0.3s ease;
  webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.tabsview.active {
  background-color: var(--raven-red) !important;
  border-radius: 5px;
  color: #fff !important;
  text-align: center !important;
}

.tabsview.tab-btn {
  color: var(--raven-gray);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-20);
  min-height: 50px;
  min-width: 10%;
}

.multi-select-reports-header .css-b62m3t-container {
  min-width: 200px;
  max-width: 200px;
}

.multi-select-reports-header .react-select-prefix__control {
  max-height: 100px !important;
  overflow: auto;
}

#Subtraction_747,
#Union_24 {
  fill: white !important;
}

.p-highlight {
  background: var(--raven-red);
}

.p-component {
  background-color: white;
}

.p-inputwrapper,
.p-inputwrapper input {
  width: 100%;
  font-weight: 100 !important;
  font-family: var(--font-brandon-regular) !important;
  font-size: 20px !important;
}

.p-inputwrapper input {
  border: 1px solid black;
}

.p-component ul {
  padding: 0 !important;
  border: 1px solid black;
}

.p-component ul li {
  padding: 0 3px !important;
}

.p-component ul li:hover {
  background: var(--raven-red);
  color: white;
}

.height-12vh {
  height: 12vh !important;
}

.size-bg-catalogue {
  background-color: #f3f3f3 !important;
}

.multicolorblocks:first-child {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.multicolorblocks:last-child {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.printerIcon svg {
  width: 30px;
  height: 30px;
}
.disabled_timer {
  position: relative;
}

.disabled_timer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--raven-gray);
  opacity: 0.5;
}

.workflow-accordion .accordion-button {
  box-shadow: none;
  border: 0px;
  background-color: transparent !important;
  color: var(--raven-red);
  font-family: var(--font-brandon-medium);
  font-size: var(--font-size-18);
}

.workflow-accordion .accordion-button.collapsed {
  color: var(--raven-gray);
}

.workflow-accordion .accordion-button:not(.collapsed) {
  border-bottom: 1px solid var(--tab-color);
}

.workflow-accordion .accordion-item {
  border: 1px solid var(--tab-color);
  border-radius: 5px;
}

.workflow-accordion .accordion-button:not(.collapsed)::after {
  background-image: url(./icons/accordionOpen.svg) !important;
  transform: rotate(0deg) !important;
}

.workflow-accordion .accordion-button::after {
  background-image: url(./icons/accordionClosed.svg) !important;
}

.p-autocomplete-panel {
  z-index: 9999 !important;
}